import React from "react";
//Hooks
import useAuth from "../../../hooks/useAuth";

//Images
import Logo from "../../../assets/img/logo.svg";
import MailSentImage from "../../../assets/img/mail-sent.svg"

//Material UI
import Grid from "@material-ui/core/Grid";



export default function MailSent(props){

    const user = useAuth();
    if(user){
        window.location.href = props.history.goBack();
    }else{
        return(
            <div className="recover-password-background">
                <div className="h-100">
                    <Grid container spacing={0} className="d-flex justify-content-center align-items-md-center h-100">
                        <Grid xs={10} md={6} lg={4}>
                            <div className="card--large">
                                <img className="pt-5 mb-5 logo d-block mx-auto" src={Logo} alt="Wikicid Logo" />
                                <h3 className="h3 text-center mb-3 a-text-bold-second-neutral-100">Recupera tu cuenta</h3>
                                <p className="text-center text-normal a-text-regular-second-neutral-60 mb-3">Se ha enviado un correo para recuperar tu cuenta.</p>
                                <div>
                                    <img src={MailSentImage} alt="Mail Sent" className="d-block m-auto"/>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }


}